import { post } from '@/scripts/http';
import { LiveParam } from "./type/live-type";
const islogin = localStorage.getItem('token') ? 'auth' : "api";
export default {
  ServiceURL: `/zone/course/${islogin}/live`,
  // 获取直播列表
  async list(data: LiveParam) {
    const res = await post(`${this.ServiceURL}/listLive`, data);
    return res;
  },
  //观看登录
  async viewLive(data: LiveParam) {
    const res = await post(`${this.ServiceURL}/process/viewLive`, data);
    return res;
  },
  //加入直播
  async joinLive(data: LiveParam) {
    const res = await post(`${this.ServiceURL}/process/joinLive`, data);
    return res;
  },
  //获取参与人员
  async listParticipant(data: LiveParam) {
    const res = await post(`${this.ServiceURL}/process/listParticipant`, data);
    return res;
  },
  //订阅直播
  async bookLive(data: LiveParam) {
    const res = await post(`${this.ServiceURL}/bookLive`, data);
    return res;
  },
  //取消订阅直播
  async cancelBook(data: LiveParam) {
    const res = await post(`${this.ServiceURL}/cancelBook`, data);
    return res;
  },    
}
